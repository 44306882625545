.active {
  border-bottom-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgb(162 203 85 / var(--tw-border-opacity));
  opacity: 1;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.akkordeon-grid {
  margin-left: 4.166666666666666%;
  margin-right: 4.166666666666666%;
  margin-top: 1.75rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  column-gap: 1.25rem;
  row-gap: 1.75rem;
}

@media (max-width: 993px) {

  .akkordeon-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 768px) {

  .akkordeon-grid {
    margin-top: 2rem;
  }

  .akkordeon-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 480px) {

  .akkordeon-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.brand-size {
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.akkordeon-btn {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 2rem;
  text-align: left;
}

.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-active {
  animation: slide-bottom 0.3s linear both;
  transition: opacity 0.3s ease;
  transition: height 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadein-enter-active {
  animation: slide-bottom 0.5s linear both;
  transition: opacity 0.5s ease;
  transition: height 2s ease;
}

.fadein-enter-from {
  opacity: 0;
}

.wide-enter-active,
.wide-leave-active {
  transition: border-bottom 0.5s ease-in-out;
}

.wide-enter-from,
.wide-leave-to {
  border-bottom: 0;
}
@keyframes slide-bottom {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.header-container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
@media (max-width: 993px) {

  .header-container {
    display: none;
  }
}

.header-grid {
  display: grid;
  grid-auto-flow: column;
  gap: 1.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  width: 100%;
}

.grid4ColumnTextWrapper {
  margin-top: 4.166666666666666%;
  margin-bottom: 4.166666666666666%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
}

@media (max-width: 993px) {

  .grid4ColumnTextWrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .grid4ColumnTextWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {

  .grid4ColumnTextWrapper {
    column-gap: 1.5rem;
  }

  .grid4ColumnTextWrapper {
    row-gap: 2.25rem;
  }
}

.introImageWrapper {
  position: relative;
}

@media (max-width: 993px) {

  .introImageWrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {

  .introImageWrapper {
    padding-bottom: 1rem;
  }
}

.introImageText {
  position: absolute;
  --tw-bg-opacity: 1;
  background-color: rgb(162 203 85 / var(--tw-bg-opacity));
  right: 0px;
  bottom: 0px;
  z-index: 10;
  margin-bottom: 8.333333333333332%;
  margin-right: 8.333333333333332%;
  max-width: 24rem;
  padding: 3rem;
}

@media (max-width: 480px) {

  .introImageText {
    left: 0px;
  }

  .introImageText {
    right: 0px;
  }

  .introImageText {
    bottom: 0px;
  }

  .introImageText {
    margin-left: 4.166666666666666%;
    margin-right: 4.166666666666666%;
  }

  .introImageText {
    margin-bottom: 0px;
  }

  .introImageText {
    max-width: none;
  }

  .introImageText {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.grid2ColumnsImageTextWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.166666666666666%;
  max-width: 1600px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (max-width: 1600px) {

  .grid2ColumnsImageTextWrapper {
    margin-left: 8.333333333333332%;
    margin-right: 8.333333333333332%;
  }
}

@media (max-width: 1400px) {

  .grid2ColumnsImageTextWrapper {
    margin-left: 4.166666666666666%;
    margin-right: 4.166666666666666%;
  }
}

.grid2ColumnsImageText {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 993px) {

  .grid2ColumnsImageText {
    grid-auto-flow: row;
  }

  .grid2ColumnsImageText {
    grid-template-columns: none;
  }
}

@media (max-width: 768px) {

  .grid2ColumnsImageText {
    gap: 2rem;
  }
}

.gridShopInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding-left: 2rem;
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-left-color: rgb(162 203 85 / var(--tw-border-opacity));
}

@media (max-width: 993px) {

  .gridShopInfo {
    padding-left: 1rem;
  }
}

.gridAnsprechsperson {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(162 203 85 / var(--tw-border-opacity));
  padding-left: 2rem;
}

@media (max-width: 993px) {

  .gridAnsprechsperson {
    padding-left: 1rem;
  }
}

.grid2erAssetWrapper {
  display: grid;
  height: 16.666667%;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  margin-top: 4.166666666666666%;
  --tw-bg-opacity: 1;
  background-color: rgb(162 203 85 / var(--tw-bg-opacity));
}

@media (max-width: 768px) {

  .grid2erAssetWrapper {
    grid-template-columns: none;
  }

  .grid2erAssetWrapper {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
}

.grid2erAssetWrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

@media (max-width: 1600px) {

  .grid2erAssetWrapper {
    margin-left: 8.333333333333332%;
    margin-right: 8.333333333333332%;
  }
}

@media (max-width: 1400px) {

  .grid2erAssetWrapper {
    margin-left: 4.166666666666666%;
    margin-right: 4.166666666666666%;
  }
}

@media (max-width: 993px) {

  .grid2erAssetWrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

.grid2erTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: 8.333333333333332%;
  margin-right: 8.333333333333332%;
}

@media (min-width: 1200px) {

  .grid2erTextWrapper {
    margin-bottom: 5rem;
  }
}

@media (max-width: 768px) {

  .grid2erTextWrapper {
    grid-row-start: 1;
  }
}

.openingHoursGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 1400px) {

  .openingHoursGrid {
    gap: 0.5rem;
  }
}

@media (max-width: 993px) {

  .openingHoursGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {

  .openingHoursGrid {
    gap: 0.25rem;
  }
}

.openingHoursColWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 3rem;
  padding-bottom: 1.75rem;
}

@media (max-width: 768px) {

  .openingHoursColWrapper {
    padding-top: 2rem;
  }
}

@media (max-width: 993px) {

  .openingHoursColWrapper {
    padding: 2rem;
  }
}

@media (max-width: 480px) {

  .openingHoursColWrapper {
    padding: 1.25rem;
  }

  .openingHoursColWrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.overviewNewsWrapper {
  margin-top: 4.166666666666666%;
  margin-bottom: 4.166666666666666%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  row-gap: 2rem;
}

@media (max-width: 768px) {

  .overviewNewsWrapper {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (min-width: 994px) {

  .overviewNewsWrapper {
    row-gap: 5rem;
  }
}

@media (max-width: 993px) {

  .overviewNewsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {

  .overviewNewsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-teammember {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  row-gap: 1.75rem;
}

@media (max-width: 768px) {

  .grid-teammember {
    grid-template-columns: none;
  }

  .grid-teammember {
    grid-template-rows: 1fr max-content;
  }
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 2.75rem;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.footerNavContainer {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: 6rem;
}

@media (max-width: 768px) {

  .footerNavContainer {
    margin-left: 8.333333333333332%;
    margin-right: 8.333333333333332%;
  }

  .footerNavContainer {
    grid-auto-flow: row;
  }

  .footerNavContainer {
    row-gap: 1.75rem;
  }
}

@media (min-width: 769px) {

  .footerNavContainer {
    margin-left: auto;
  }
}

.footerImpressumContainer {
  margin-top: 2.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
}

@media (max-width: 768px) {

  .footerImpressumContainer {
    flex-direction: column;
  }

  .footerImpressumContainer {
    row-gap: 1.25rem;
  }
}

@media (min-width: 769px) {

  .footerImpressumContainer {
    margin-top: 1.75rem;
  }
}

.mobileNavButtonsFlex {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
  height: 100%;
  width: 100%;
}

.z-header {
  z-index: 999;
}

img {
  max-width: 100%;
}

.mask {
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.bg-img__10-16, .bg-img__round, .bg-img__square, .bg-img__4-3, .bg-img__16-10, .bg-img__16-9, .bg-img__2-1, .bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.bg-img__contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-img__2-1 {
  padding-top: 50%;
}
.bg-img__16-9 {
  padding-top: 56.25%;
}
.bg-img__16-10 {
  padding-top: 62.5%;
}
.bg-img__4-3 {
  padding-top: 75%;
}
.bg-img__square {
  padding-top: 100%;
}
.bg-img__round {
  width: 100%;
  border-radius: 666px;
  padding-top: 100%;
}
.bg-img__overlay:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.25;
}
.bg-img__10-16 {
  padding-top: 160%;
}

.brandImg {
  width: 100%;
  display: block;
}
.brandImg:before {
  content: "";
  display: block;
  padding-top: 80%;
}

.slider {
  position: relative;
  display: block;
  width: 100%;
}

.slick-slider {
  position: relative;
  box-sizing: border-box;
  display: block;
  -webkit-user-select: none;
          user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  margin: 0px;
  display: block;
  height: 100%;
  overflow: hidden;
  padding: 0px;
  cursor: grab;
}
.slick-list:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.slick-list.dragging {
  cursor: move;
  cursor: grabbing;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0px;
  top: 0px;
  margin-right: auto;
  height: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-start;
  display: -ms-flexbox;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  min-height: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.slick-slide .slide {
  position: relative;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging a, .slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-slide > div {
  display: grid;
  height: 100%;
  align-items: center;
}

.slick-arrow {
  cursor: pointer;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev, .slick-next {
  position: absolute;
  top: 100%;
  z-index: 10;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.slick-prev .fa-angle-right::before, .slick-prev .fa-angle-left::before, .slick-next .fa-angle-right::before, .slick-next .fa-angle-left::before {
  font-size: 34px;
}

.slick-prev {
  position: absolute;
  left: 0.25rem;
  top: 50%;
  margin-right: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgb(31 129 155 / var(--tw-text-opacity));
  transform: translate(-100%, -50%);
}
.slick-next {
  position: absolute;
  right: 0.25rem;
  top: 50%;
  margin-left: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgb(21 82 99 / var(--tw-text-opacity));
  transform: translate(100%, -50%);
}
.slick-dots {
  list-style-type: none;
  padding-top: 0.75rem;
  padding-left: 0px;
  text-align: center;
  line-height: 0;
}
.slick-dots > li {
  display: inline-block;
  padding: 0.25rem;
  vertical-align: top;
}
.slick-dots > li + li {
  margin-left: 0.25rem;
}
.slick-dots > li > button {
  display: block;
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
  overflow: hidden;
  border-radius: 9999px;
  width: 0.625rem;
  height: 0.625rem;
  color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(31 129 155 / var(--tw-bg-opacity));
}
.slick-dots > li.slick-active > button {
  --tw-bg-opacity: 1;
  background-color: rgb(162 203 85 / var(--tw-bg-opacity));
}

.shopTitle {
  word-spacing: 100vw;
}

ul {
  list-style-type: disc;
  padding-left: 1rem;
}

ol {
  list-style-type: decimal;
  padding-left: 1rem;
}

.description {
  display: inline-block;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
}

@media (max-width: 1600px) {

  .wrapper {
    margin-left: 8.333333333333332%;
    margin-right: 8.333333333333332%;
  }
}

@media (max-width: 1400px) {

  .wrapper {
    margin-left: 4.166666666666666%;
    margin-right: 4.166666666666666%;
  }
}

a {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

html {
  --tw-text-opacity: 1;
  color: rgb(21 82 99 / var(--tw-text-opacity));
  font-family: franklin-gothic-urw-comp, book;
  font-size: 1.125rem;
}
@media (max-width: 768px) {
  html {
    font-size: 1rem;
  }
}

@supports ((-webkit-hyphens: auto) or (hyphens: auto)) {
  body body {
    text-align: justify;
    -webkit-hyphens: auto;
            hyphens: auto;
  }
}

h3 {
  font-family: franklin-gothic-urw-comp, book;
  font-weight: 300;
  font-size: 20px;
}

@media (max-width: 768px) {
  .franklinDemi-f1 {
    font-size: 2.25rem;
  }
  .franklinDemi-f2 {
    font-size: 1.75rem;
  }
  .franklinDemi-f4 {
    font-size: 1.125rem;
  }
}

.btn-wide-green-blue, .btn-wide-white-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  height: 45px;
}

.btn-wide-white-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media (min-width: 1200px) {

  .btn-wide-blue-white-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 129 155 / var(--tw-bg-opacity));
  }

  .btn-wide-blue-white-hover:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.btn-wide-green-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(162 203 85 / var(--tw-bg-opacity));
}

.linkDark {
  --tw-text-opacity: 1;
  color: rgb(21 82 99 / var(--tw-text-opacity));
}

@media (min-width: 994px) {

  .linkDark:hover {
    --tw-text-opacity: 1;
    color: rgb(36 166 200 / var(--tw-text-opacity));
  }

  .group:hover .linkDark {
    --tw-text-opacity: 1;
    color: rgb(36 166 200 / var(--tw-text-opacity));
  }
}

.linkDark {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.linkLightBlue {
  --tw-text-opacity: 1;
  color: rgb(36 166 200 / var(--tw-text-opacity));
}

@media (min-width: 994px) {

  .linkLightBlue:hover {
    --tw-text-opacity: 1;
    color: rgb(162 203 85 / var(--tw-text-opacity));
  }

  .group:hover .linkLightBlue {
    --tw-text-opacity: 1;
    color: rgb(162 203 85 / var(--tw-text-opacity));
  }
}

.linkLightBlue {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.linkWhite {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 994px) {

  .linkWhite:hover {
    --tw-text-opacity: 1;
    color: rgb(21 82 99 / var(--tw-text-opacity));
  }

  .group:hover .linkWhite {
    --tw-text-opacity: 1;
    color: rgb(21 82 99 / var(--tw-text-opacity));
  }
}

.linkWhite {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.nav-link {
  --tw-text-opacity: 1;
  color: rgb(21 82 99 / var(--tw-text-opacity));
}

@media (min-width: 994px) {

  .nav-link:hover {
    --tw-text-opacity: 1;
    color: rgb(162 203 85 / var(--tw-text-opacity));
  }
}

a {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}